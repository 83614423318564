<template>
  <div>
    <!-- eslint-disable max-len -->
    <svg
      class="indigo lighten-5 ma-3"
      width="100%"
      :height="players.length * 100"
    >
      <arrow2-lstart />

      <template v-for="player in orderBy(players, 'order')">
        <text-rotate
          :key="player.id * 100 + 1"
          :x="+50 - ( player.order * 100 )"
          :y="25.87996"
          :text="player.text"
          transform="rotate(-90)"
        />
        <line
          :key="player.id * 100 + 2"
          :x1="0"
          :x2="1720"
          :y1="player.order * 100"
          :y2="player.order * 100"
          stroke="#c1c2c3"
          :stroke-width="1"
          @click="apagandoLinha()"
        />
      </template>
      <template v-for="(itemSequence,itemSequenceIndex) in linearSequenceCalculated">
        <flowchart-card
          v-if="itemSequenceIndex === 0"
          :key="itemSequenceIndex * 10 + 1"
          :identification="itemSequence.id"
          :x="+itemSequence.father.x + itemSequenceIndex * 2"
          :y="+itemSequence.father.y"
          :title="itemSequence.father.text"
          :text="`S:${itemSequence.son.id}  ${itemSequence.father.description}`"
          :illuminate="lightingTest(illuminate, itemSequence) || false"
          @lightingUp="lightingUp"
        />
        <flowchart-card
          v-if="itemSequence.son.draw && itemSequenceIndex < 6"
          :key="itemSequenceIndex * 10 + 2"
          :identification="itemSequence.id"
          son
          :x="+itemSequence.son.x + itemSequenceIndex * 2"
          :y="+itemSequence.son.y"
          :title="itemSequence.son.text"
          :text="`F:${itemSequence.father.id} S:${itemSequence.son.id}  ${itemSequence.son.description}`"
          :illuminate="lightingTest(illuminate, itemSequence) || false"
          @lightingUp="lightingUp"
        />
        <!--        :title="itemSequence.son.text"-->
        <path-component
          v-if="itemSequenceIndex < 6"
          :key="itemSequenceIndex * 10 + 3"
          :color="colors[itemSequenceIndex]"
          :d="itemSequence.reason.path"
          stroke-dasharray="2.33,2"
          :stroke-width="0.865"
        />
        <!--        :color="`#${((1 << 24) * Math.random() | 0).toString(16)}`"-->
        <!--        color="#0404B4"-->
      </template>
    </svg>
    <!--      <g-->
    <!--        @mousedown="md($event, 1)"-->
    <!--        @touchstart="md($event, 1)"-->
    <!--      <g-->
    <!--        @mousedown="md($event, 2)"-->
    <!--        @touchstart="md($event, 2)"-->
    <!--      >-->
    <!--    <div>{{ isMove }}, {{ x }}, {{ isMove2 }}, {{ xPart2 }}</div>-->
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue2-filters
import Vue2Filters from 'vue2-filters';
import Arrow2Lstart from '@/views/common/widget/Flowchart/Arrow2Lstart.vue';
import TextRotate from '@/views/common/widget/Flowchart/TextRotate.vue';
import PathComponent from '@/views/common/widget/Flowchart/PathComponent.vue';
import FlowchartCard from '@/views/common/widget/Flowchart/FlowchartCard.vue';

export default {
  name: 'WidgetFlowchart',
  components: {
    FlowchartCard,
    TextRotate,
    Arrow2Lstart,
    PathComponent,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    colors: [
      '#000066',
      '#ff0000',
      '#009933',
      '#ff0066',
      '#990000',
      '#008000',
      '#003366',
      '#ff9900',
      '#6600cc',
      '#990033',
      '#900066',
      '#0f0000',
      '#109933',
      '#2f0066',
      '#390000',
      '#408000',
      '#503366',
      '#6f9900',
      '#7600cc',
      '#890030',
      '#000063',
      '#ff0003',
      '#009936',
      '#ff5063',
      '#993003',
      '#000006',
      '#000360',
      '#ff0903',
      '#6693cc',
      '#994063',
      '#906036',
      '#0f3000',
      '#101933',
      '#2f6066',
      '#398000',
      '#409000',
      '#509366',
      '#6f3900',
      '#7640cc',
      '#893033',
    ],
    linearSequence: [
      {
        id: 1,
        father: {
          id: 1,
          player: {
            id: 1,
            text: 'interessado',
            order: 1,
          },
          text: 'Início',
          description: 'Preenchimento do requerimento',
          draw: true,
        },
        son: {
          id: 2,
          player: {
            id: 2,
            text: 'OACO',
            order: 2,
          },
          text: 'Conferencia Documental',
          description: 'Analise doc...',
          draw: true,
        },
        reason: {
          id: 1,
          text: '',
        },
      },
      {
        id: 2,
        father: {
          id: 2,
          player: {
            id: 2,
            text: 'OACO',
            order: 2,
          },
          text: 'Conferencia Documental',
          description: 'Analise doc...',
          draw: false,
        },
        son: {
          id: 3,
          player: {
            id: 3,
            text: 'OAGA',
            order: 3,
          },
          text: 'Analise Tecnica',
          description: '3 Enc ...',
          draw: true,
        },
        reason: {
          id: 1,
          text: 'Favorável',
        },
      },
      {
        id: 3,
        father: {
          id: 2,
          player: {
            id: 2,
            text: 'OACO',
            order: 2,
          },
          text: 'Conferencia Documental',
          description: 'Analise doc...',
          draw: false,
        },
        son: {
          id: 4,
          player: {
            id: 4,
            text: 'ICA',
            order: 4,
          },
          text: 'Teste Aderência',
          description: 'OAGA 2',
          draw: true,
        },
        reason: {
          id: 2,
          text: 'Não Favorável',
        },
      },
      {
        id: 4,
        father: {
          id: 4,
          player: {
            id: 4,
            text: 'ICA',
            order: 4,
          },
          text: 'Teste Aderência',
          description: 'OAGA 2',
          draw: false,
        },
        son: {
          id: 5,
          player: {
            id: 1,
            text: 'interessado',
            order: 1,
          },
          text: '5 Enc5 2344221312',
          description: '5 Enc s542e we  asa  askl ',
          draw: true,
        },
        reason: {
          id: 1,
          text: 'Favorável',
        },
      },
      {
        id: 5,
        father: {
          id: 3,
          player: {
            id: 1,
            text: 'interessado',
            order: 1,
          },
          text: '3 Enc',
          description: '3 Enc asa asjk askl ',
          draw: false,
        },
        son: {
          id: 6,
          player: {
            id: 2,
            text: 'OACO',
            order: 2,
          },
          text: '6 Enc das5 2344221312',
          description: '6 Enc  s542e we  asa  askl ',
          draw: true,
        },
        reason: {
          id: 1,
          text: 'Favorável',
        },
      },
      {
        id: 6,
        father: {
          id: 6,
          player: {
            id: 2,
            text: 'OACO',
            order: 2,
          },
          text: '6 Enc das5 ',
          description: '6 Enc d2sa5a  we  asa  askl ',
          draw: false,
        },
        son: {
          id: 5,
          player: {
            id: 1,
            text: 'interessado',
            order: 1,
          },
          text: '5 Enc 2344221312',
          description: '5 Enc s542e we  asa',
          draw: false,
        },
        reason: {
          id: 1,
          text: 'Favorável',
        },
      },
    ],
    players: [
      {
        id: 1,
        text: 'Interessado',
        order: 1,
        columnAvailable: 0,
      },
      {
        id: 2,
        text: 'OACO',
        order: 2,
        columnAvailable: 0,
      },
      {
        id: 3,
        text: 'OAGA',
        order: 3,
        columnAvailable: 0,
      },
      {
        id: 4,
        text: 'ICA',
        order: 4,
        columnAvailable: 0,
      },
    ],
    linearSequenceCalculated: [],
    illuminate: {}, // iluninar card relacionados ao click
    isMove: false,
    x: 20,
    y: 25,
    xPart2: 120,
    isMove2: false,
    width: 200,
  }),
  computed: {
    rectWidth() {
      return this.x < this.xPart2 ? this.xPart2 - this.x : this.x - this.xPart2;
    },
    rectX() {
      return this.x < this.xPart2 ? this.x : this.xPart2;
    },
  },
  mounted() {
    this.updatingSequence();
  },
  methods: {
    lightingTest(illuminate, itemSequence) {
      console.log(illuminate, itemSequence);
      if (illuminate.id === itemSequence.id) {
        console.log('ele mesmo');
        return true;
      }
      if (illuminate.children) {
        if (illuminate.children.includes(itemSequence.father.id) && illuminate.isSon) {
          console.log('cond 1 ');
          return true;
        } if (illuminate.parents.includes(itemSequence.son.id) && illuminate.isSon) {
          console.log('cond 2 ');
          return true;
        }
      }
      console.log('nada');
      return false;
    },
    lightingUp(event) {
      const selected = this.linearSequenceCalculated.find(
        (item) => item.id === event.identification,
      );
      this.illuminate = {
        id: selected.id,
        children: selected.son.children,
        parents: selected.son.parents,
        isSon: event.son,
      };
      // const filtrando = this.linearSequence.filter((item) => {
      //   if (event.son) {
      //     return (item.id === selected.id)
      //       || (item.father.id === selected.son.id)
      //       || (item.son.id === selected.father.id);
      //   }
      //   return item.father.id === selected.father.id || item.son.id === selected.son.id;
      // });
      // this.illuminate = filtrando.map((cada) => cada.id);
      // console.log('this.illuminate');
      // console.log(this.illuminate);
    },
    apagandoLinha() {
      console.log('apagandoLinha');
    },
    md(ev, val) {
      if (val === 1) {
        this.isMove = true;
      } else if (val === 2) {
        this.isMove2 = true;
      }
      document.addEventListener('mousemove', this.mm);
      document.addEventListener('mouseup', this.mu);
      document.addEventListener('touchmove', this.tm);
      document.addEventListener('touchend', this.mu);
    },
    mu() {
      this.isMove = false;
      this.isMove2 = false;
      document.removeEventListener('mousemove', this.mm);
      document.removeEventListener('mouseup', this.mu);
      document.removeEventListener('touchmove', this.tm);
      document.removeEventListener('touchend', this.mu);
    },
    mm(ev) {
      if (this.isMove) {
        // todo: fix magic number
        this.x = ev.clientX - 10;
      } else if (this.isMove2) {
        this.xPart2 = ev.clientX - 10;
      }
    },
    tm(ev) {
      if (this.isMove) {
        // todo: fix magic number
        this.x = ev.touches[0].clientX - 10;
      } else if (this.isMove2) {
        this.xPart2 = ev.touches[0].clientX - 10;
      }
    },
    updatingSequence() {
      const sequence = [];
      let { father } = this.linearSequence[0];
      let { son } = this.linearSequence[this.linearSequence.length - 1];
      let fatherId = 0;
      let sonId = 0;
      let path = '';
      // eslint-disable max-len
      let columnSon = 0;
      this.linearSequence.forEach((item, itemIndex) => {
        sonId = item.son.id;
        fatherId = item.father.id;

        sequence[itemIndex] = {};
        sequence[itemIndex].id = item.id;

        let fatherImediado = this.linearSequence[0];
        const playerSon = this.players.findIndex(
          (player) => player.id === item.son.player.id,
        );
        columnSon = this.players[playerSon].columnAvailable;
        if (itemIndex === 0) {
          const playerFather = this.players.findIndex(
            (player) => player.id === item.father.player.id,
          );
          sequence[itemIndex].father = {
            ...item.father,
            column: this.players[playerFather].columnAvailable,
            x: +60 + this.players[playerFather].columnAvailable * 200,
            y: +15 + (item.father.player.order - 1) * 100,
          };
          this.players[playerFather].columnAvailable += 1;
        } else {
          sequence[itemIndex].father = item.father;
          fatherImediado = sequence.find(
            (sequencia) => sequencia.son.id === fatherId,
          );
          if (fatherImediado.son.column) {
            columnSon = Math.max(
              fatherImediado.son.column + 1, this.players[playerSon].columnAvailable,
            );
          }
        }

        columnSon = columnSon || 1;

        sequence[itemIndex].son = {
          ...item.son,
          column: columnSon,
          x: +60 + columnSon * 200,
          y: +15 + (item.son.player.order - 1) * 100,
          children: [],
          parents: [],
        };

        let indexTemp1 = -1;
        indexTemp1 = this.linearSequence.findIndex(
          (findSon) => findSon.son.id === sonId,
        );
        if (!sequence[indexTemp1].son.children.includes(sonId)) {
          sequence[indexTemp1].son.children.push(sonId);
        }
        if (!sequence[indexTemp1].son.parents.includes(fatherId)) {
          sequence[indexTemp1].son.parents.push(fatherId);
        }
        console.log('sequence[indexTemp1].son');
        console.log(sequence[indexTemp1].son);
        if (sequence[itemIndex].son.draw) {
          this.players[playerSon].columnAvailable += 1;
        }
        son = sequence[indexTemp1].son;
        const steps = [];

        let indexTemp2 = -1;
        indexTemp2 = sequence.findIndex(
          (findFather) => [findFather.son.id].includes(fatherId),
        );

        father = sequence[0].father;
        if (sequence[indexTemp2]) {
          father = sequence[indexTemp2].son;
        }
        steps[0] = {
          x: +175 + father.x,
          y: +22 + father.y + itemIndex * 3,
        };
        steps[1] = {
          x: +185 + father.x + itemIndex,
          y: +22 + father.y + itemIndex * 3,
        };
        let inversorVertical = 0;
        if (father.y < son.y) {
          inversorVertical = 1;
        } else if (father.y > son.y) {
          inversorVertical = -1;
        }
        const subtractionY = inversorVertical * (son.y - father.y);
        const phases = subtractionY / 50;

        let x = +185 + father.x + itemIndex;
        let y = +22 + father.y + itemIndex * 3;
        x -= father.x - son.x + 200;
        for (let i = 0; i <= phases; i += 1) {
          if (i === 0) {
            y += (inversorVertical * (i + 1) * 50);
            steps.push({
              x: +185 + father.x + itemIndex,
              y,
            });
          } else if (i === 1) {
            steps.push({ x, y });
          } else {
            y += (inversorVertical * 50);
            steps.push({ x, y });
          }
        }
        const ultima = {
          x: +3 + son.x + itemIndex,
          y: +22 + son.y + itemIndex * 3,
        };
        steps.push(ultima);
        path = 'M ';
        steps.forEach((caminho, caminhoIndex) => {
          path += `${caminho.x},${caminho.y}`;
          if (steps.length - 1 !== caminhoIndex) {
            path += ' ';
          }
        });
        sequence[itemIndex].reason = {
          ...item.reason,
          path,
        };
      });
      this.linearSequenceCalculated = sequence;
    },
  },
};
</script>
