<template>
  <!-- eslint-disable max-len -->
  <foreignObject
    width="190"
    height="200"
    :x="x"
    :y="y"
  >
    <div class="indigo lighten-4 mx-5">
      <v-btn
        x-small
        :color="illuminate ? 'warning' : 'primary'"
        block
        class="mr-5"
        width="125"
        @click="lightingUp()"
      >
        {{ title }}
      </v-btn>
      <p
        class="mx-2 font-weight-light"
        style="font-size: 12px;"
      >
        {{ text }}
      </p>
    </div>
  </foreignObject>
<!--  requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"-->
</template>
<script>
export default {
  name: 'FlowchartCard',
  props: {
    identification: {
      type: Number,
      default: 0,
    },
    son: {
      type: Boolean,
    },
    illuminate: {
      type: Boolean,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
  methods: {
    lightingUp() {
      this.$emit('lightingUp', { son: this.son, identification: this.identification });
    },
  },
};
</script>
