<template>
  <!-- eslint-disable max-len -->
  <path
    id="path3876"
    :style="`fill:none;
        stroke:${color};
        stroke-width:${hover ? 2 : strokeWidth};
        stroke-linecap:butt;
        stroke-linejoin:miter;
        stroke-opacity:1;
        stroke-miterlimit:4;
        stroke-dasharray:${strokeDasharray};
        marker-end:url(#Arrow2Lend)`"
    :d="d"
    @mouseover="mouseEvents(true)"
    @mouseleave="mouseEvents(false)"
    @click="apagandoLinha()"
  />
</template>
<script>
export default {
  name: 'PathComponent',
  props: {
    d: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    /* se a linha é continua ou trações
    * primeiro valor comprimento dos trações
    * segundo valor distancia entre os trações
    * exemplo "2.33,2"
     */
    strokeDasharray: {
      type: String,
      default: 'none',
    },
    strokeWidth: {
      type: Number,
      default: 0.5,
    },
  },
  data: () => ({
    hover: false,
  }),
  methods: {
    mouseEvents(hover) {
      this.hover = hover;
    },
  },
};
</script>
