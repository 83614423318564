<template>
  <!-- eslint-disable max-len -->
  <div>
    <marker
      id="Arrow2Lstart"
      inkscape:stockid="Arrow2Lstart"
      orient="auto"
      ref-y="0.0"
      ref-x="0.0"
      style="overflow:visible"
      inkscape:isstock="true"
      inkscape:collect="always"
    >
      <path
        id="path4701"
        style="fill-rule:evenodd;stroke-width:0.625;stroke-linejoin:round;stroke:#000000;stroke-opacity:1;fill:#000000;fill-opacity:1"
        d="M 8.7185878,4.0337352 L -2.2072895,0.016013256 L 8.7185884,-4.0017078 C 6.9730900,-1.6296469 6.9831476,1.6157441 8.7185878,4.0337352 z "
        transform="scale(1.1) translate(1,0)"
      />
    </marker>
    <marker
      id="marker5679"
      inkscape:isstock="true"
      style="overflow:visible"
      ref-x="0.0"
      ref-y="0.0"
      orient="auto"
      inkscape:stockid="Arrow2Lstart"
    >
      <path
        id="path5677"
        transform="scale(1.1) translate(1,0)"
        d="M 8.7185878,4.0337352 L -2.2072895,0.016013256 L 8.7185884,-4.0017078 C 6.9730900,-1.6296469 6.9831476,1.6157441 8.7185878,4.0337352 z "
        style="fill-rule:evenodd;stroke-width:0.625;stroke-linejoin:round;stroke:#000000;stroke-opacity:1;fill:#000000;fill-opacity:1"
      />
    </marker>
    <marker
      id="marker5235"
      inkscape:isstock="true"
      style="overflow:visible;"
      ref-x="0.0"
      ref-y="0.0"
      orient="auto"
      inkscape:stockid="Arrow2Lend"
    >
      <path
        id="path5233"
        transform="scale(1.1) rotate(180) translate(1,0)"
        d="M 8.7185878,4.0337352 L -2.2072895,0.016013256 L 8.7185884,-4.0017078 C 6.9730900,-1.6296469 6.9831476,1.6157441 8.7185878,4.0337352 z "
        style="fill-rule:evenodd;stroke-width:0.625;stroke-linejoin:round;stroke:#000000;stroke-opacity:1;fill:#000000;fill-opacity:1"
      />
    </marker>
    <marker
      id="marker4975"
      inkscape:isstock="true"
      style="overflow:visible;"
      ref-x="0.0"
      ref-y="0.0"
      orient="auto"
      inkscape:stockid="Arrow2Lend"
    >
      <path
        id="path4973"
        transform="scale(1.1) rotate(180) translate(1,0)"
        d="M 8.7185878,4.0337352 L -2.2072895,0.016013256 L 8.7185884,-4.0017078 C 6.9730900,-1.6296469 6.9831476,1.6157441 8.7185878,4.0337352 z "
        style="fill-rule:evenodd;stroke-width:0.625;stroke-linejoin:round;stroke:#000000;stroke-opacity:1;fill:#000000;fill-opacity:1"
      />
    </marker>
    <marker
      id="Arrow2Lend"
      inkscape:stockid="Arrow2Lend"
      orient="auto"
      ref-y="0.0"
      ref-x="0.0"
      style="overflow:visible;"
      inkscape:isstock="true"
      inkscape:collect="always"
    >
      <path
        id="path4704"
        style="fill-rule:evenodd;stroke-width:0.625;stroke-linejoin:round;stroke:#000000;stroke-opacity:1;fill:#000000;fill-opacity:1"
        d="M 8.7185878,4.0337352 L -2.2072895,0.016013256 L 8.7185884,-4.0017078 C 6.9730900,-1.6296469 6.9831476,1.6157441 8.7185878,4.0337352 z "
        transform="scale(1.1) rotate(180) translate(1,0)"
      />
    </marker>
  </div>
</template>
<script>
export default {
  name: 'Arrow2Lstart',
  data: () => ({
  }),
};
</script>
