<template>
  <!-- eslint-disable max-len -->
  <text
    id="text12"
    xml:space="preserve"
    style="font-style:normal;
        font-weight:normal;
        font-size:10.58333302px;
        line-height:1.25;
        font-family:sans-serif;
        letter-spacing:0px;
        word-spacing:0px;
        fill:#000000;
        fill-opacity:1;
        stroke:none;
        stroke-width:0.26458332;
        text-anchor:middle;
        text-align:center;"
    :x="x"
    :y="y"
    :transform="transform"
  >
    <tspan
      id="tspan10"
      sodipodi:role="line"
      :x="x"
      :y="y"
      style="stroke-width:0.26458332;
      text-anchor:middle;
      text-align:center;"
    >
      {{ text }}
    </tspan>
  </text>
</template>
<script>
export default {
  name: 'TextRotate',
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    transform: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
};
</script>
