<template>
  <div class="container--fluid fill-height">
    <PageTitulo :title="$t('route.fluxograma')" />
    <widget-flowchart title="oioioi" />
  </div>
</template>
<script>
import PageTitulo from '@/views/common/widget/WidgetTitulo.vue';
import WidgetFlowchart from '@/views/common/widget/WidgetFlowchart.vue';

export default {
  name: 'Fluxograma',
  components: {
    PageTitulo,
    WidgetFlowchart,
  },
  data: () => ({
  }),
};
</script>
